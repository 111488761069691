
// import React from 'react';
import React, { useState } from 'react';

const Faq = () => {
    const [faq, setFaq] = useState([
        {
            question: 'Do I need to plan my food and accommodation?',
            answer: 'No, we shall provide you with all the facilities including food and accommodation throughout the event.',
            open: false
        },
        {
            question: 'Should I have a team with me to participate in the event?',
            answer: 'No, you need not have a team of your own. All individual registrants will be grouped and teams of 1-5 shall be formed accordingly.',
            open: false
        },
        {
            question: 'Is there any Code of Conduct?',
            answer: 'Yes, as this is a MLH sponsored event, we follow <a href="https://mlh.io/code-of-conduct" target="_blank" style="text-decoration: underline">MLH\'s Code of Conduct</a>.',
            open: false
        },
        {
            question: 'How do I reach for support? ',
            answer: 'For on duty support, we will be having representatives across the concourse where you shall reach out for support. For off duty support, you may contact us through our discord or reach out to us via email available on our website.',
            open: false
        },
        {
            question: 'Who can attend?',
            answer: 'Anyone who is an Indian national and is aged between 18 - 34 years is eligible to register for TechEden 2.0! We encourage college students as well as young professionals to enter.',
            open: false
        }
    ]);

    const toggleFaq = (index) => {
        setFaq(faq.map((item, i) => {
            if (i === index) {
                item.open = !item.open;
            } else {
                item.open = false;
            }

            return item;
        }));
    }

    return (
        <section id="FAQ" className="py-10 bg-black sm:py-16 lg:py-24 ">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Frequently Asked Questions</h2>
                    
                </div>

                <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    {faq.map((item, index) => (
                        <div key={index} className="transition-all duration-200 bg-zinc-900 rounded-lg shadow-lg shadow-neutral-900 cursor-pointer hover:bg-zinc-900">
                            <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6" onClick={() => toggleFaq(index)}>
                                <span className="flex text-lg font-semibold text-white"> {item.question} </span>

                                <svg className={`w-6 h-6 text-white ${item.open ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>

                            <div className={`${item.open ? 'block' : 'hidden'} px-4 pb-5 sm:px-6 sm:pb-6`}>
                                <p className='text-white' dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                            </div>
                        </div>
                    ))}
                </div>

                <p className="text-center text-white textbase mt-9">Didn’t find the answer you are looking for? <a href="https://forms.gle/AsB5d2wYTLMRStBYA" target="_blank" rel="noopener noreferrer" title="" className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline">Contact our support</a></p>
            </div>
        </section>
    );
}

export default Faq;
