import Header from "./components/Header";
import Hero from "./layouts/Hero";
import Timeline from "./layouts/Timeline";
import Sponsors from "./layouts/Sponsors";
import FAQ from "../src/layouts/FAQ"
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Header/>
      <Hero/>
      <Timeline/>
     <Sponsors/>
     <FAQ/>
     <Footer/>
   </div>
  )
}
  
export default App;
