import logo from "../assets/logo.png";
import OSC from "../assets/OSC.png";

function Footer () {
     return (
        <div>
            <div className="bg-black ">
                <div className="flex justify-center align-center m-auto pb-7">
                <span className="font-bold text-transparent text-2xl p-2.5 bg-clip-text bg-gradient-to-r from-[#fd746c] to-[#ff9068]">Bought To You By</span>
                    <a href="https://oscvitap.org/" aria-label="osc" target="_blank" rel="noopener noreferrer">
                        <img src={OSC} alt="" className="w-20" />
                    </a>
                    
                </div>
            </div>
        </div>
     );
}

export default Footer;