import React, { useState } from "react";
import pattern from "../assets/pattern.png";
import heroimg from "../assets/hero.png";

const Hero = () => {
  return (
    <div className="bg-slate-900">
      <section className="relative py-12 sm:py-16 lg:pb-40">
        <div className="absolute bottom-0 right-0 overflow-hidden">
          <img
            className="w-full h-auto origin-bottom-right transform scale-150 lg:w-auto lg:mx-auto lg:object-cover lg:scale-75"
            src={pattern}
            alt=""
          />
        </div>

        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-4 lg:items-center lg:grid-cols-2 xl:grid-cols-2">
            <div className="text-center xl:col-span-1 lg:text-left md:px-16 lg:px-0 xl:pr-20">
              <h1 className="text-4xl font-bold leading-tight text-gray-50 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">
                TechEden 2022 Hack + code
              </h1>
              <p className="mt-3 text-lg text-gray-400 sm:mt-5 font-inter">
                TechEden is a Hackathon for students to showcase their
                brainstorming and coding skills. Here the participants can
                propose ideas to tackle issues that they think need a solution
                and then work on them to provide a prototype or part of the
                solution. Participants will be working with their teammates to
                stir up the solution.
              </p>

              <a
                href="https://forms.gle/TbTKX8EYqxRYiRCS8"
                target="_blank"
                rel="noopener noreferrer"
                title=""
                className="inline-flex px-8 py-4 mt-8 text-lg font-bold text-black transition-all duration-200 bg-gray-50 border border-transparent rounded sm:mt-10 font-pj hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Register Now
              </a>
            </div>

            <div className="xl:col-span-1 ">
              <img className="w-full mx-auto" src={heroimg} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Hero;
