import Sponsor from "../../src/Data/sponsors.json";

function Card ({imgurl, redirect}) {

    return (
        <div>
            <div className = "h-[200px] w-[350px] overflow-hidden bg-white text-center m-[35px] rounded-xl">
                <a className = "h-full w-full flex justify-center align-center" target="_blank" rel="noopener noreferrer" href= {redirect} >
                    <img className = "p-2 m-auto w-9/12 max-w-xs h-auto" src = {imgurl} />
                </a>
            </div>
        </div>
    );
}


function Sponsors () {

    return (
        <>
            <div id="Sponsors" className = "bg-black text-center">
                <span className = "text-indigo-500 leading-tight mt-10 mb-20 text-4xl sm:text-5xl lg:text-6xl font-bold">Our Sponsors</span>
                <div className = "p-2.5">
                    <div className = "flex flex-wrap justify-center align-center">
                        {Sponsor.map((card) => 
                            (<Card 
                            imgurl = {card.imgurl}
                            redirect = {card.redirect}
                            />))
                        }
                    </div>
                </div>
            </div>
        </>
     );
}

export default Sponsors;